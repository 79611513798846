<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar color="transparent" flat>
      <v-btn class="btn-icon" icon @click="handleDrawer()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-toolbar-title class="pl-2">
        Boas vindas, {{ _user.name }}
      </v-toolbar-title>

      <v-spacer />

      <v-avatar size="2.5rem" color="neutral6">
        <v-icon color="white">mdi-account-outline</v-icon>
      </v-avatar>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      class="side-drawer rounded-r-xl"
      color="neutral"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex justify-center py-4">
          <v-img
            :src="require('@/assets/jp2pay/logomark-outlined.svg')"
            class="mr-2"
            max-width="1.75rem"
            height="1.75rem"
            contain
          />

          <span class="text-h6 font-weight-bold"> JP2PAY </span>
        </div>

        <v-list class="py-0" color="transparent" nav>
          <router-link
            v-for="(item, i) in _routes"
            v-slot="{ href, navigate, isActive }"
            class="mb-1"
            :to="item.path"
            :key="i"
            custom
          >
            <v-list-item class="router-item" :href="href" @click="navigate">
              <v-list-item-icon class="mr-2">
                <v-icon :color="isActive ? 'primary' : 'background3'">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title :class="{ 'primary--text': isActive }">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </div>

      <template v-slot:append>
        <v-list class="transparent" nav>
          <v-list-item @click="signOut()">
            <v-list-item-icon class="mr-2">
              <v-icon color="background3"> mdi-logout </v-icon>
            </v-list-item-icon>

            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <router-view />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Main",

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    ...mapGetters({
      _user: "user",
    }),

    _routes() {
      return [
        {
          icon: "mdi-account-multiple-outline",
          label: "Usuários",
          path: "/users",
        },
        {
          icon: "mdi-file-sign",
          label: "Ordens",
          path: "/orders",
        },
      ];
    },
  },

  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },

    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
