import Axios from "axios";
import store from "@/store";
import router from "@/router";

const api = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 16000,
});

if (store && store.getters.token)
  api.defaults.headers.common["Authorization"] =
    "Bearer " + store.getters.token;

api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => {
    if (
      (err === "Error: Network Error" || err.response?.status === 401) &&
      !!store.getters.token
    ) {
      store.dispatch("setSignOut");
      router.push({ path: "/" }).catch(() => {
        /* ignore */
      });
    }

    return Promise.reject(err.response);
  }
);

export default api;
