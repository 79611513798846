<template>
  <v-layout class="auth-wrapper">
    <v-container fluid>
      <v-row class="fill-height" align="center" justify="center">
        <v-col class="py-6" cols="12" sm="8" md="6" lg="4" xl="3">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style lang="scss" src="./style.scss" scoped />
