import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../modules/main/Main.vue";
import Auth from "../modules/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Users" },
  },
  {
    path: "/",
    name: "Main",
    redirect: { name: "Users" },
    component: Main,
    children: [
      {
        path: "orders",
        name: "Orders",
        meta: { title: "Ordens", requiresAuth: true },
        component: () => import("@/modules/main/views/orders/Orders.vue"),
      },
      {
        path: "users",
        name: "Users",
        redirect: { name: "UsersList" },
        component: () => import("@/modules/main/views/users/Users.vue"),
        children: [
          {
            path: "",
            name: "UsersList",
            meta: { title: "Usuários", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/users/children/usersList/UsersList.vue"
              ),
          },
          {
            path: ":id",
            name: "UserDetails",
            redirect: { name: "UserProfile" },
            component: () =>
              import(
                "@/modules/main/views/users/children/userDetails/UserDetails.vue"
              ),
            children: [
              {
                path: "profile",
                name: "UserProfile",
                meta: { title: "Detalhes do usuário", requiresAuth: true },
                component: () =>
                  import(
                    "@/modules/main/views/users/children/userDetails/children/userProfile/UserProfile.vue"
                  ),
              },
              {
                path: "orders",
                name: "UserOrders",
                meta: { title: "Ordens do usuário", requiresAuth: true },
                component: () =>
                  import(
                    "@/modules/main/views/users/children/userDetails/children/userOrders/UserOrders.vue"
                  ),
              },
              {
                path: "idwall-report",
                name: "IdwallReport",
                meta: { title: "Relatório Idwall", requiresAuth: true },
                component: () =>
                  import(
                    "@/modules/main/views/users/children/userDetails/children/idwallReport/IdwallReport.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "SignIn" },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        meta: { title: "Entrar", preventAuth: true },
        component: () => import("@/modules/auth/views/signIn/SignIn.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "Users" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
